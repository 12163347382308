.container,
.title,
.description {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 4px;
  line-height: 17px;
  opacity: 0.35;
  color: #000;
}

.description {
  line-height: 17px;
  color: #000;
}

.description a{
  text-decoration: none;
}

.description span {
  display: block;
}
