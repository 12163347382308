#popup-root .popup-content {
  background: transparent;
  padding: 0;
  border: 0;
  height: 100%;
  width: 80%;
  margin: auto;
}
#popup-root .popup-overlay {
  background: rgba(35, 35, 35, 0.85);
   overflow: auto; 
}
