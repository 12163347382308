.headLeft,
.title,
.count,
.headRight {
  margin: 0;
  padding: 0;
}

.headContainer {
  display: flex;
  margin-top: 0px;
  margin-right: 22px;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .headContainer {
    flex-direction: column-reverse;
    margin: 0;
  }
}

.headLeft {
  margin-right: 40px;
}

.title {
  display: flex;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  word-wrap: break-word;
  color: var(--primary-color);
}

.count {
  margin-right: 21px;
  position: relative;
  top: -12px;
  font-size: 64px;
  line-height: 77px;
  text-align: center;
  color: var(--primary-color);
}

.headRight {
  margin-left: auto;
}

@media (max-width: 576px) {
  .headRight {
    margin: 0 0 20px;
  }
}
