@import "../node_modules/modern-normalize/modern-normalize.css";
@import "./ui/fonts/fonts.css";

body {
  font-family: "Gilroy", system-ui, -apple-system, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f8f9;
  font-size: 14px;
  line-height: 1.5;
  color: #000000;
}

* {
  outline-color: var(--primary-color);
}

html,
body,
#root {
  height: 100%;
}

:root {
  --primary-color: #4ec0e4;
  --primary-darker-color: #2ab5df;
  --primary-transparent-color: rgb(78 192 228 / 75%);
  --secondary-text-color: #212121;
  --light-text-color: #a6a6a6;
  --decoration-text-color: #8ace21;

  /*
    we cant store breakpoints as css variable
    so just remebmer and use only: 576px 768px 992px 1200px 1400px
  */
}

:root {
  accent-color: var(--primary-color);
}
:focus-visible {
  outline-color: var(--primary-color);
}
::selection {
  background-color: var(--primary-color);
}
::marker {
  color: var(--primary-color);
}

::placeholder {
  color: rgb(33, 33, 33, 0.2);
}

label {
  user-select: none;
}

.not-ready {
  position: relative;
  pointer-events: none;
  cursor: default;
  user-select: none;
}

.not-ready::after {
  position: absolute;
  z-index: 100;
  left: -4px;
  top: -4px;
  right: -4px;
  bottom: -4px;
  content: "";
  background: red;
  opacity: 0.2;
  border-radius: 10px;
}
